import React, { lazy, Suspense, useState, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IMaskInput } from 'react-imask';
import { Dialog } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { createContext } from 'react';

// =============== PAGES =================
const Home = lazy(() => import('./pages/Home'))
const Noticias = lazy(() => import('./pages/Noticias'))
const InfoNoticia = lazy(() => import('./pages/InfoNoticia'))
const InfoGrupo = lazy(() => import('./pages/InfoGrupo'))
const Documentos = lazy(() => import('./pages/Documentos'))
const Login = lazy(() => import('./pages/Logar'))
const Perfil = lazy(() => import('./pages/Perfil'))
const Calendario = lazy(() => import('./pages/Calendario'))
const Ramais = lazy(() => import('./pages/Ramais'))
const Boletins = lazy(() => import('./pages/Boletins'))
const ViewNotificacoes = lazy(() => import('./pages/ViewNotificacoes'))
const Mensagens = lazy(() => import('./pages/Mensagens'))
const Reset = lazy(() => import('./pages/Reset'))
const ResetSolicita = lazy(() => import('./pages/ResetSolicita'))
const Comunicados = lazy(() => import('./pages/Destaques'))
const NossosProgramas = lazy(() => import('./pages/Nossos_programas'))
const SaudeVoce = lazy(() => import('./pages/SaudeVoce'))
const FalaGestor = lazy(() => import('./pages/FalaGestor'))
const FalaGestorView = lazy(() => import('./pages/FalaGestorView'))
const SaudeVoceView = lazy(() => import('./pages/SaudeVoceView'))
const AcessoNegado = lazy(() => import('./pages/Acessonegado'))
const ListGrupos = lazy(() => import('./pages/ListGrupos'))
const TermoAcesso = lazy(() => import('./pages/TermoAcesso'))
const TermoAcessoRead = lazy(() => import('./pages/TermoAcessoRead'))
const Apresentacao = lazy(() => import('./pages/Apresentacao'))
const InteligenciaEmSaude = lazy(() => import('./pages/InteligenciaEmSaude'))
const SistemasSMS = lazy(() => import('./pages/SistemasSMS'))
const Memorial = lazy(() => import('./pages/Memorial'))
const Wiki = lazy(() => import('./pages/Wiki'))
const OneWiki = lazy(() => import('./pages/Wiki/oneWiki'))
const SalaReuniao = lazy(() => import('./pages/SalaReuniao'))
const Page404 = lazy(() => import('./pages/Page404'))

// =============== Componentes =================
const Podcast = lazy(() => import('./componentes/Podcast'))
const Rodape = lazy(() => import('./componentes/Rodape'))
const Menu = lazy(() => import('./componentes/Menu'))

const history = createBrowserHistory();



const PrivateRotas = ({ children, isAuthenticated, reset, termo, ...rest }) => (
    <Route {...rest} history={history} render={({ location }) => {
        if (isAuthenticated === true && reset === false && termo === true) {
            return children
        }
        else if (isAuthenticated === true && reset === true) {
            return (<Redirect to={{ pathname: "/reset-Senha", state: { from: location } }} />)
        }
        else if (isAuthenticated === true && reset === false && termo === false) {
            return (<Redirect to={{ pathname: "/termo", state: { from: location } }} />)
        }
        else {
            return (<Redirect to={{ pathname: `/acessar`, state: { from: location } }} />)
        }
    }
    } />)

const PrivateRotas2N = ({ children, isAuthenticated, ...rest }) => (
    <Route {...rest} history={history} render={({ location }) => {
        if (isAuthenticated === true) {
            return children
        }
        else {
            return <Redirect to={{ pathname: "/acessar", state: { from: location } }} />
        }
    }
    } />)

const ShowTheLocation = () => {
    const location = useLocation();
    return <Page404 locale_match={location.pathname} />
}


function Rotas(props) {

    const { csrf, username, isAuthenticated, ip, session, name_user, Open_Or_Close_PD, StartPodcast, fotoUser, termo, reset, tipoUser, iOS, View_auth, minha_unidade, update_info, aoFechar, info_busca_users_dados, Up_Info_User, update_info_form_erro, telefone_info, telefone_info_view, aoFecharTelefone, TelefoneSearch, telefone_uni_dep, view_ramal } = props

    const mask = [{ mask: '0000-0000' }, { mask: '(00) 0000-0000' }, { mask: '(00) 00000-0000' }];

    useEffect(() => {
        if (info_busca_users_dados.telefone) {
            TelefoneSearch(info_busca_users_dados.telefone)
        }
    }, [])

    return (
        <BrowserRouter>
            <Suspense fallback={
                <>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100vh', backgroundColor: 'rgb(2, 16, 50, 0.9)' }}>
                        <object data={require('./style/img/logo-pmsp.svg').default} type="image/svg+xml" width={"220px"} />
                    </div>
                </>
            }>
                <Menu logout={props.logout} isAuthenticated={isAuthenticated} termo={termo} reset={reset} endereco={ip} username={username} fotoUser={fotoUser} tipoUser={tipoUser} View_auth={View_auth} minha_unidade={minha_unidade} view_ramal={view_ramal} />

                <Modal
                    className="modal_iframe_view"
                    open={update_info}
                    onClose={aoFechar}
                ><>
                        <div className="Container-View-att-info">
                            <div className="icon-close-not">
                                <CloseIcon onClick={() => aoFechar()} className="TabNetBntClose" />
                            </div>
                            <div className='modal-Atualizar-info-user'>
                                <h1>Atualize seus dados!</h1>
                                <span>
                                    {username}, para garantir uma melhor gestão administrativa, por gentileza, atualize os
                                    dados indicados abaixo:
                                </span>
                                <div className="TabNet-view info-att-form-dysplay">
                                    <form onSubmit={(e) => Up_Info_User(e)}>
                                        <div className="PrincipalConteudo " >
                                            <div className="ListaInfoUser">

                                                <div className="ColunaPerfilInfo1">
                                                    <div>
                                                        <span> Nome : </span>
                                                        <input type="text" maxLength="100" name="nome" value={info_busca_users_dados.nome} disabled />
                                                        <span class="erros_form"></span>
                                                    </div>
                                                    <div>
                                                        <span> Email : </span>
                                                        <input type="text" maxLength="255" name="email" value={info_busca_users_dados.email} disabled />
                                                        <span class="erros_form"></span>
                                                    </div>
                                                    <div>
                                                        <span> Telefone : </span>

                                                        <IMaskInput
                                                            mask={mask}
                                                            definitions={{
                                                                '#': /[1-9]/,
                                                            }}
                                                            name="telefone"
                                                            defaultValue={info_busca_users_dados.telefone}
                                                            maxLength={15}
                                                            minLength={9}
                                                            onBlur={(e) => TelefoneSearch(e.target.value)}
                                                            autofocus
                                                        />

                                                        <span class="erros_form">{update_info_form_erro.telefone}</span>
                                                        <span class="erros_form">{update_info_form_erro.__all__}</span>
                                                    </div>
                                                    <div>
                                                        <span> Unidade : </span>
                                                        <input type="text" maxLength="255" value={info_busca_users_dados.unidade__unidade_nome} disabled />
                                                    </div>
                                                </div>
                                                <div className="ColunaPerfilInfo2">
                                                    <div>
                                                        <span> Data de Nascimento : </span>
                                                        <input type="date" name="nascimento" defaultValue={info_busca_users_dados.nascimento} />
                                                        <span class="erros_form">{update_info_form_erro.nascimento}</span>
                                                    </div>
                                                    <div>
                                                        <span> Função : </span>
                                                        <input type="text" maxLength="255" name="funcao" defaultValue={info_busca_users_dados.funcao} />
                                                        <span class="erros_form">{update_info_form_erro.funcao}</span>
                                                    </div>
                                                    <div>
                                                        <span> Servidor/Colaborador: </span>
                                                        <input type="text" maxLength="255" disabled value={info_busca_users_dados.tipo_funcionario} />
                                                    </div>
                                                    <div>
                                                        <span> Departamento: </span>
                                                        <div className="DepartamentoList">
                                                            <input type="text" maxLength="255" disabled name="departamento" value={info_busca_users_dados.departamento__departamento_nome} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {!info_busca_users_dados.departamento__departamento_nome | !info_busca_users_dados.unidade__unidade_nome ? <>
                                                <span class="erros_form aling-error-uni-dep">
                                                    Para atualizar a unidade e/ou departamento, entre em contato com o Moderador de
                                                    Unidade Virtual do seu setor ou com o suporte.
                                                </span>
                                            </> : <></>}

                                            <div className="btn-att-modal">
                                                <button className="SalvarBtn" type='submit'> Salvar </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                        <Dialog
                            className="modal_memorial_cadastro"
                            open={telefone_info_view}
                            onClose={aoFecharTelefone}
                        >
                            <div className="icon-close-not">
                                <CloseIcon onClick={() => aoFecharTelefone()} className="TabNetBntClose" />
                            </div>
                            <h3 className="TituloModalTag" style={{ display: "flex", justifyContent: "center", fontSize: "3rem", color: "#ced662" }}><FontAwesomeIcon icon={faInfoCircle} size='lg' /></h3>

                            <p>O Ramal: <strong>{telefone_info.ramal}</strong>
                                pertence a Unidade: <strong>{telefone_info.unidade__unidade_nome}</strong> e Departamento: <strong>{telefone_info.departamento__departamento_nome}</strong>.
                                Você não pertence à está Unidade ou Departamento.</p>

                            <p>
                                Por favor, verifique se o Ramal inserido está correto ou entre em
                                contato com o Moderador de Unidade Virtual do seu setor ou suporte
                                para atualizar seu cadastro:
                            </p>
                            <a href='mailto:smssuportesistemas@PREFElTURA.SP.GOV.BR' style={{ display: "flex", justifyContent: "center", }}>smssuportesistemas@PREFElTURA.SP.GOV.BR</a>
                        </Dialog>
                    </>

                </Modal>

                <div id="FecharDrop">
                    <Switch>
                        <PrivateRotas exact path="/" name="Home" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><Home endereco={ip} session={session} StartPodcast={StartPodcast} isAuthenticated={isAuthenticated} termo={termo} reset={reset} /></PrivateRotas>

                        <PrivateRotas exact path="/list/noticias" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><Noticias endereco={ip} session={session} isAuthenticated={isAuthenticated} termo={termo} reset={reset} /></PrivateRotas>

                        <PrivateRotas exact path="/infoNoticias/:id" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><InfoNoticia endereco={ip} session={session} isAuthenticated={isAuthenticated} termo={termo} reset={reset} /></PrivateRotas>

                        <PrivateRotas exact path="/Comunicados" isAuthenticated={isAuthenticated} termo={termo} reset={reset}> <Comunicados endereco={ip} session={session} isAuthenticated={isAuthenticated} termo={termo} reset={reset} /> </PrivateRotas>

                        <PrivateRotas exact path="/Boletins/:categoria" isAuthenticated={isAuthenticated} termo={termo} reset={reset}> <Boletins endereco={ip} session={session} isAuthenticated={isAuthenticated} termo={termo} reset={reset} /> </PrivateRotas>

                        <PrivateRotas exact path="/Apresentacao/:id" isAuthenticated={isAuthenticated} termo={termo} reset={reset}> <Apresentacao endereco={ip} session={session} isAuthenticated={isAuthenticated} termo={termo} reset={reset} /> </PrivateRotas>

                        <PrivateRotas exact path="/NossosProgramas" isAuthenticated={isAuthenticated} termo={termo} reset={reset}> <NossosProgramas endereco={ip} session={session} isAuthenticated={isAuthenticated} termo={termo} reset={reset} /> </PrivateRotas>

                        <PrivateRotas exact path="/SaudeVoce/:categoria" isAuthenticated={isAuthenticated} termo={termo} reset={reset}> <SaudeVoce endereco={ip} session={session} isAuthenticated={isAuthenticated} termo={termo} reset={reset} /> </PrivateRotas>

                        <PrivateRotas exact path="/:categoria/saudevoceView/:id" isAuthenticated={isAuthenticated} termo={termo} reset={reset}> <SaudeVoceView endereco={ip} session={session} isAuthenticated={isAuthenticated} termo={termo} reset={reset} /> </PrivateRotas>

                        <PrivateRotas exact path="/FalaGestor" isAuthenticated={isAuthenticated} termo={termo} reset={reset}> <FalaGestor endereco={ip} session={session} isAuthenticated={isAuthenticated} termo={termo} reset={reset} /> </PrivateRotas>

                        <PrivateRotas exact path="/list/grupos" isAuthenticated={isAuthenticated} termo={termo} reset={reset}> <ListGrupos endereco={ip} session={session} isAuthenticated={isAuthenticated} termo={termo} reset={reset} /> </PrivateRotas>

                        <PrivateRotas exact path="/FalaGestorView/:id" isAuthenticated={isAuthenticated} termo={termo} reset={reset}> <FalaGestorView endereco={ip} session={session} isAuthenticated={isAuthenticated} termo={termo} reset={reset} /> </PrivateRotas>

                        <PrivateRotas exact path="/calendario" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><Calendario isAuthenticated={isAuthenticated} termo={termo} reset={reset} session={session} endereco={ip} csrf={csrf} /></PrivateRotas>

                        <PrivateRotas exact path="/calendario/:id" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><Calendario isAuthenticated={isAuthenticated} termo={termo} reset={reset} session={session} endereco={ip} csrf={csrf} /></PrivateRotas>

                        <PrivateRotas exact path="/infoGrupos/:id" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><InfoGrupo isAuthenticated={isAuthenticated} termo={termo} reset={reset} endereco={ip} session={session} csrf={csrf} username={username} name_user={name_user} iOS={iOS} /></PrivateRotas>

                        <PrivateRotas exact path="/doc" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><Documentos isAuthenticated={isAuthenticated} termo={termo} reset={reset} endereco={ip} session={session} csrf={csrf} /></PrivateRotas>

                        <PrivateRotas exact path="/perfil" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><Perfil isAuthenticated={isAuthenticated} termo={termo} reset={reset} endereco={ip} session={session} csrf={csrf} fotoUser={fotoUser} telefone_info={telefone_info} telefone_info_view={telefone_info_view} aoFecharTelefone={aoFecharTelefone} TelefoneSearch={TelefoneSearch} telefone_uni_dep={telefone_uni_dep} /></PrivateRotas>

                        <PrivateRotas exact path="/ramais" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><Ramais isAuthenticated={isAuthenticated} termo={termo} reset={reset} endereco={ip} session={session} csrf={csrf} /></PrivateRotas>

                        <PrivateRotas exact path="/notificacoes" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><ViewNotificacoes isAuthenticated={isAuthenticated} termo={termo} reset={reset} session={session} endereco={ip} csrf={csrf} /></PrivateRotas>

                        <PrivateRotas exact path="/mensagens" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><Mensagens isAuthenticated={isAuthenticated} termo={termo} reset={reset} endereco={ip} session={session} csrf={csrf} /></PrivateRotas>

                        <PrivateRotas2N exact path="/reset-Senha" isAuthenticated={isAuthenticated} termo={termo} reset={reset}> <Reset isAuthenticated={isAuthenticated} termo={termo} reset={reset} endereco={ip} errorReset={props.errorReset} resetF={props.resetF} /> </PrivateRotas2N>

                        <PrivateRotas2N exact path="/termo" isAuthenticated={isAuthenticated} termo={termo} reset={reset}> <TermoAcesso isAuthenticated={isAuthenticated} termo={termo} reset={reset} endereco={ip} errorReset={props.errorReset} resetF={props.resetF} Aceita_termo={props.Aceita_termo} username={username} name_user={name_user} logout={props.logout} /> </PrivateRotas2N>

                        <PrivateRotas exact path="/ler/termo" isAuthenticated={isAuthenticated} termo={termo} reset={reset}> <TermoAcessoRead isAuthenticated={isAuthenticated} termo={termo} reset={reset} endereco={ip} errorReset={props.errorReset} resetF={props.resetF} Aceita_termo={props.Aceita_termo} username={username} name_user={name_user} /> </PrivateRotas>

                        <PrivateRotas exact path="/acesso-negado/:id" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><AcessoNegado session={session} endereco={ip} isAuthenticated={isAuthenticated} termo={termo} reset={reset} /></PrivateRotas>

                        <PrivateRotas exact path="/InteligenciaEmSaude" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><InteligenciaEmSaude session={session} endereco={ip} isAuthenticated={isAuthenticated} termo={termo} reset={reset} /></PrivateRotas>

                        <PrivateRotas exact path="/SistemasSMS" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><SistemasSMS session={session} endereco={ip} isAuthenticated={isAuthenticated} termo={termo} reset={reset} /></PrivateRotas>

                        <PrivateRotas exact path="/Memorial" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><Memorial session={session} endereco={ip} isAuthenticated={isAuthenticated} termo={termo} reset={reset} /></PrivateRotas>

                        <PrivateRotas exact path="/wiki" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><Wiki session={session} endereco={ip} isAuthenticated={isAuthenticated} termo={termo} reset={reset} /></PrivateRotas>

                        <PrivateRotas exact path="/wiki/one/:day/:month/:year/:slug" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><OneWiki session={session} endereco={ip} isAuthenticated={isAuthenticated} termo={termo} reset={reset} /></PrivateRotas>

                        <PrivateRotas exact path="/ReservarSala" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><SalaReuniao session={session} endereco={ip} isAuthenticated={isAuthenticated} termo={termo} reset={reset} csrf={csrf} name_user={name_user} /></PrivateRotas>

                        <Route exact path="/ResetSolicita" isAuthenticated={isAuthenticated} termo={termo} reset={reset}> <ResetSolicita endereco={ip} session={session} csrf={csrf} /> </Route>

                        <Route exact path="/acessar" isAuthenticated={isAuthenticated} termo={termo} reset={reset}><Login isAuthenticated={isAuthenticated} termo={termo} reset={reset} endereco={ip} error={props.error} csrf={csrf} logar={props.logar} session={session} history={history} /></Route>

                        <Route path="*"> <ShowTheLocation /></Route>

                    </Switch>
                </div>
                <Podcast Open_Or_Close_PD={Open_Or_Close_PD} />
                <Rodape />
            </Suspense>
        </BrowserRouter >
    )
}


export default Rotas;
